import { SearchId } from '@bbx/common/api/dtos/search-id'
import { NextDynamicRoute } from '@bbx/common/routing/nextDynamicRoutes'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { SearchHistoryComponent, useLastSearches } from '@bbx/search-journey/common/components/SearchHistory/SearchHistory'
import { Navigator, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { ModalNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/ModalNavigator'
import { ProvinceNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/ProvinceNavigator/ProvinceNavigator'
import { RangeNavigatorComponent } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/RangeNavigatorComponent/RangeNavigatorComponent'
import { useStartPageSearch } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/useStartPageSearch'
import { detailSearchUrl } from '@bbx/search-journey/sub-domains/search/lib/detailSearchUrl'
import { getNavigatorById, getRangeNavigatorById } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { Text } from '@wh-components/core/Text/Text'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { sortAlphabetically } from '@wh/common/chapter/lib/commonHelpers'
import { formatNumber } from '@wh/common/chapter/lib/formatter'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { verticalIdMap, verticals } from '@wh/common/chapter/types/verticals'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import React, { Fragment, FunctionComponent, useContext } from 'react'
import { css, useTheme } from 'styled-components'
import { GeoNavigator } from '../../result-list/Navigators/GeoNavigator'
import { StickySearchButtonMobile } from '../../../../common/start-pages/StickySearchButtonMobile'
import { SearchIdNavigator } from '@bbx/search-journey/sub-domains/search/components/common/result-list/SearchIdNavigator/SearchIdNavigator'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'

export interface SearchBoxProps {
    initialSearchResult: SearchResult
}

export const SearchBox: FunctionComponent<SearchBoxProps> = ({ initialSearchResult }) => {
    const { searchHistory } = useLastSearches(verticalIdMap.ESTATE)

    const advertisingState = useContext(AdvertisingStateContext)
    const theme = useTheme()
    const searchBoxBackgroundColor = advertisingState.pageModifications.backgroundColors?.['estate-search-box']
    const searchBoxBorderColor = advertisingState.pageModifications.borders?.['estate-search-box']
    const searchBoxForegroundTextColor = advertisingState.pageModifications.foregroundColors?.['estate-search-box-text']
    const searchBoxForegroundLinkColor = advertisingState.pageModifications.foregroundColors?.['estate-search-box-links']
    const searchBoxSearchButtonOutlineActivated = advertisingState.pageModifications.borders?.['estate-search-box-search-button'] ?? false

    const { currentSearchResult, resetSearch, triggerSearch, isSearchInProgress, abortRequest } = useStartPageSearch(
        initialSearchResult,
        NextDynamicRoute.REAL_ESTATE_RESULT_LIST,
    )

    const provinceNavigator = getNavigatorById(currentSearchResult, 'province')
    const districtNavigator = getNavigatorById(currentSearchResult, 'district')
    const locationNavigator = getNavigatorById(currentSearchResult, 'location-autocomplete')

    const areaNavigator =
        getRangeNavigatorById(currentSearchResult, 'area') ??
        getRangeNavigatorById(currentSearchResult, 'livingarea') ??
        getRangeNavigatorById(currentSearchResult, 'completearea') ??
        getRangeNavigatorById(currentSearchResult, 'plotarea')
    const priceNavigator = getRangeNavigatorById(currentSearchResult, 'price')
    const pricesqmNavigator = getRangeNavigatorById(currentSearchResult, 'pricesqm')
    const nrofroomsNavigator = getNavigatorById(currentSearchResult, 'nrofrooms')
    const searchIdNavigator = getNavigatorById(currentSearchResult, 'searchId')

    const noOfDynamicNavigators = [areaNavigator, priceNavigator, pricesqmNavigator, nrofroomsNavigator].filter(Boolean).length

    return (
        <Box
            backgroundColor={searchBoxBackgroundColor ?? { phone: 'palette.polarbear', tablet: 'semantic.surface' }}
            padding={{ tablet: 'm' }}
            paddingTop={{ phone: 'm' }}
            paddingBottom="s"
            border={{ tablet: searchBoxBorderColor ? `1px solid ${searchBoxBorderColor}` : 'owl' }}
            borderRadius={{ tablet: 'm' }}
            data-search-in-progress={isSearchInProgress}
            testId="search-box-wrapper"
        >
            <Box paddingHorizontal={{ phone: 'm', tablet: '0' }}>
                <Text
                    as="h1"
                    fontSize="m"
                    fontWeight="semibold"
                    color={searchBoxForegroundTextColor ?? 'palette.verydarkgrey'}
                    marginBottom="xs"
                >
                    Welche Immobilie suchst du?
                </Text>
                <SearchIdNavigator
                    navigator={searchIdNavigator}
                    onSearch={triggerSearch}
                    ariaLabel="Welche Immobilie suchst du?"
                    taggingData={currentSearchResult.taggingData}
                    marginTop={0}
                    marginBottom="m"
                    width={{ tablet: '380px' }}
                    getTaggingAction={(searchId) => searchIdToTaggingAction[searchId]}
                />

                <Text
                    display="block"
                    color={searchBoxForegroundTextColor ?? 'palette.verydarkgrey'}
                    fontWeight="semibold"
                    marginBottom="xs"
                >
                    Wo suchst du?
                </Text>
                <Box display="flex" flexDirection={{ phone: 'column', tablet: 'row' }} alignItems={{ tablet: 'center' }} marginBottom="m">
                    {provinceNavigator && (
                        <ProvinceNavigator
                            navigator={provinceNavigator}
                            districtNavigator={districtNavigator}
                            onSearch={triggerSearch}
                            width={{ phone: '100%', tablet: 'calc(50% - 16px)' }}
                            showSelectedValues={false}
                            showResetLink={false}
                            taggingData={currentSearchResult.taggingData}
                            taggingEvent="real_estate_vertical_home_show_all_regions_click"
                            vertical={verticals.ESTATE}
                        />
                    )}

                    {locationNavigator && (
                        <Fragment>
                            <Text
                                fontSize="s"
                                fontStyle="italic"
                                color="palette.elephant"
                                marginVertical={{ phone: 'xs', tablet: 0 }}
                                marginHorizontal={{ tablet: 's' }}
                            >
                                oder
                            </Text>
                            <GeoNavigator
                                navigator={locationNavigator}
                                onSearch={triggerSearch}
                                showResetLink={false}
                                width={{ phone: '100%', tablet: 'calc(50% - 16px)' }}
                                taggingData={currentSearchResult.taggingData}
                                taggingEvent="real_estate_vertical_home_autocomplete_click"
                            />
                        </Fragment>
                    )}
                </Box>

                {currentSearchResult.searchId !== SearchId.New_Constructions && (
                    <Fragment>
                        <Text
                            display={{ phone: 'block', tablet: 'none' }}
                            fontWeight="semibold"
                            color={searchBoxForegroundTextColor ?? 'palette.verydarkgrey'}
                            marginBottom="xs"
                        >
                            Basisdaten
                        </Text>

                        <Box
                            display="flex"
                            flexDirection={{ phone: 'column', tablet: 'row' }}
                            marginBottom="m"
                            css={css`
                                > *:not(:last-child) {
                                    margin-bottom: ${(p) => p.theme.space.s}px;

                                    ${(p) => p.theme.media.tablet} {
                                        margin-bottom: 0;
                                        margin-right: ${(p) => p.theme.space.s}px;
                                    }
                                }
                            `}
                        >
                            {priceNavigator && (
                                <RangeNavigatorComponent
                                    navigator={priceNavigator}
                                    width={{ phone: '100%', tablet: `${100 / noOfDynamicNavigators}%` }}
                                    onSearch={triggerSearch}
                                    abortRequest={abortRequest}
                                    mode="to"
                                    prependTo="€"
                                    placeholderTo="Preis bis"
                                    size={{ phone: 'large', tablet: 'medium' }}
                                    disabled={false}
                                    taggingData={currentSearchResult.taggingData}
                                />
                            )}

                            {pricesqmNavigator && (
                                <RangeNavigatorComponent
                                    navigator={pricesqmNavigator}
                                    width={{ phone: '100%', tablet: `${100 / noOfDynamicNavigators}%` }}
                                    onSearch={triggerSearch}
                                    abortRequest={abortRequest}
                                    mode="to"
                                    prependTo="€"
                                    placeholderTo="Preis/m² bis"
                                    size={{ phone: 'large', tablet: 'medium' }}
                                    disabled={false}
                                    taggingData={currentSearchResult.taggingData}
                                    formatInput={false}
                                />
                            )}

                            {areaNavigator && (
                                <RangeNavigatorComponent
                                    navigator={areaNavigator}
                                    width={{ phone: '100%', tablet: `${100 / noOfDynamicNavigators}%` }}
                                    onSearch={triggerSearch}
                                    abortRequest={abortRequest}
                                    mode="from"
                                    prependFrom="m²"
                                    placeholderFrom="Fläche ab"
                                    size={{ phone: 'large', tablet: 'medium' }}
                                    disabled={false}
                                    taggingData={currentSearchResult.taggingData}
                                />
                            )}

                            {nrofroomsNavigator && (
                                <Box width={{ phone: '100%', tablet: `${100 / noOfDynamicNavigators}%` }}>
                                    <ModalNavigator
                                        navigator={nrofroomsNavigator}
                                        disabled={isSearchInProgress}
                                        onSearch={triggerSearch}
                                        label="Zimmer"
                                        showSelectedValues={false}
                                        taggingData={currentSearchResult.taggingData}
                                        getSelectedValuesText={getSelectedValuesTextRoomsNavigator}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Fragment>
                )}
            </Box>

            <Box display="flex" gap={{ phone: 'sm', tablet: 's' }} flexDirection={{ phone: 'column-reverse', tablet: 'column' }}>
                <Box width="50%" alignSelf="flex-end" display={{ phone: 'none', tablet: 'block' }}>
                    <Button
                        size="large"
                        width="100%"
                        disabled={isSearchInProgress}
                        testId="search-submit-button"
                        onClick={() => {
                            BbxRouter.push({
                                href: buildSeoUrl(
                                    findContextLinkWithId('resultListSeoNavigatorLink', currentSearchResult.searchContextLinks)
                                        ?.relativePath,
                                ),
                                clientSideRouting: true,
                                beforeRouting: () =>
                                    callActionEvent(
                                        'real_estate_vertical_home_search_box_search_button_click',
                                        currentSearchResult.taggingData,
                                    ),
                            })
                        }}
                        css={css`
                            box-shadow: ${searchBoxSearchButtonOutlineActivated && `0 0 0 1px ${theme.colors.semantic.surface.DEFAULT}`};
                        `}
                    >
                        {formatNumber(currentSearchResult?.rowsFound) ?? ''} Objekte anzeigen
                    </Button>
                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingHorizontal={{ phone: 'm', tablet: '0' }}
                    paddingBottom={{ phone: 's', tablet: '0' }}
                >
                    <Box display="flex" gap="s" alignItems="center">
                        <ClientRoutingAnchorLink
                            type="anchor"
                            href={isSearchInProgress ? undefined : detailSearchUrl(currentSearchResult)}
                            testId="detailSearch"
                            onClick={() =>
                                callActionEvent('real_estate_vertical_home_search_box_detail_search_click', currentSearchResult.taggingData)
                            }
                            css={css`
                                ${isSearchInProgress && {
                                    cursor: 'not-allowed',
                                }}
                            `}
                        >
                            <Text fontSize="s" fontWeight="bold" color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}>
                                Mehr Filter
                            </Text>
                        </ClientRoutingAnchorLink>
                        {!!searchHistory?.searches.length && (
                            <Text
                                display={{ phone: 'none', tablet: 'block' }}
                                fontSize="s"
                                fontWeight="bold"
                                color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}
                            >
                                |
                            </Text>
                        )}
                        <SearchHistoryComponent
                            searchHistory={searchHistory}
                            header="Suchverlauf Immobilien"
                            display={{ phone: 'none', tablet: 'block' }}
                            color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}
                            taggingData={currentSearchResult.taggingData}
                            openActionSheetEvent="real_estate_vertical_home_last_searches_click"
                            clickLastSearchEvent="real_estate_vertical_home_last_searches_search_click"
                        />
                    </Box>
                    <ButtonWithLinkStyle disabled={isSearchInProgress} testId="resetSearch" onClick={() => resetSearch()}>
                        <Text fontSize="s" fontWeight="bold" color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}>
                            Filter zurücksetzen
                        </Text>
                    </ButtonWithLinkStyle>
                </Box>
            </Box>

            <StickySearchButtonMobile
                searchResult={currentSearchResult}
                disabled={isSearchInProgress}
                clickEvent="real_estate_vertical_home_search_box_search_button_click"
                searchBoxSearchButtonOutlineActivated={searchBoxSearchButtonOutlineActivated}
                backgroundColor={searchBoxBackgroundColor}
            >
                {formatNumber(currentSearchResult?.rowsFound) ?? ''} Objekte anzeigen
            </StickySearchButtonMobile>

            <Box justifyContent="center" paddingTop="s" display={{ phone: 'flex', tablet: 'none' }}>
                <SearchHistoryComponent
                    searchHistory={searchHistory}
                    header="Suchverlauf Immobilien"
                    color={searchBoxForegroundLinkColor ?? 'palette.primary.main'}
                    taggingData={currentSearchResult.taggingData}
                    openActionSheetEvent="real_estate_vertical_home_last_searches_click"
                    clickLastSearchEvent="real_estate_vertical_home_last_searches_search_click"
                />
            </Box>
        </Box>
    )
}

export const getSelectedValuesTextRoomsNavigator = (navigator: Navigator | RangeNavigator | undefined) => {
    const selectedValues = navigator?.selectedValues
        .map((value) => value.label)
        .sort((a, b) => sortAlphabetically(a, b))
        .join(', ')

    return `Zimmer:  ${selectedValues}`
}

const searchIdToTaggingAction: Record<number, TaggingActionEvent | undefined> = {
    [SearchId.Flat_Rent]: 'real_estate_vertical_home_dropdown_flat_for_rent',
    [SearchId.Flat_Sale]: 'real_estate_vertical_home_dropdown_flat_for_sale',
    [SearchId.House_Rent]: 'real_estate_vertical_home_dropdown_house_for_rent',
    [SearchId.House_Sale]: 'real_estate_vertical_home_dropdown_house_for_sale',
    [SearchId.Leisure_Rent]: 'real_estate_vertical_home_dropdown_vacation_property_for_rent',
    [SearchId.Leisure_Sale]: 'real_estate_vertical_home_dropdown_vacation_property_for_sale',
    [SearchId.Commercial_Rent]: 'real_estate_vertical_home_dropdown_commercial_property_for_rent',
    [SearchId.Commercial_Sale]: 'real_estate_vertical_home_dropdown_commercial_property_for_sale',
    [SearchId.Plots]: 'real_estate_vertical_home_dropdown_plot',
    [SearchId.RealEstateMisc]: 'real_estate_vertical_home_dropdown_other_objects',
    [SearchId.RealEstateCommon]: 'real_estate_vertical_home_dropdown_all_object_types',
    [SearchId.New_Constructions]: 'real_estate_vertical_home_dropdown_new_constructions',
}
